import React from 'react'
import {Link} from 'gatsby'
import scientist2 from '../../assets/images/scientist/scientist2.png'
import scientist4 from '../../assets/images/scientist/scientist4.png'
import scientist6 from '../../assets/images/scientist/scientist6.png'
import scientist8 from '../../assets/images/scientist/scientist8.png'

const TeamStyleOne = () => {
    return (
        <div className="scientist-area bg-color pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist2} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Siphamandla Phenyane</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>BSc(IS), BSc(Hons) CS & IS. Solutions Architect.</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://za.linkedin.com/in/siphamandla-phenyane-516b8b60" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist2} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Andrew Barry Boyes</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>MEng. Systems Analyst</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/andrew-boyes/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist8} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Odilon Slash Kongolo</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>PrEng, PrCM, MEng Nuclear Civil Management Consultant</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/odilon-slash-kongolo-6a396495/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist4} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Garett Llyod Sidwell</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>MEng Data Science Data Scientist</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/garett-sidwell-815211151" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist4} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Martin Leon Kemp</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>BEng Mechatronics Software Engineer</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/mlkemp/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist8} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Dr Guilaume Greyling</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>Phd AI & Data Science Award-Winning Scientist and Innovator</span>
                                
                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/guilaume-greyling-phd/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist6} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Cameron Collum</h3>
                                <span>Executive Director</span>
                                <br/>
                                <span>busSci Finance Management Consultant </span>
                                
                                <ul className="social">
                                    <li>
                                        <Link to="https://www.linkedin.com/in/cameron-collum-5a5399140" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamStyleOne